<template lang="  html">
  <div class="embed-wrapper">
    <div class="controls">
      <slot :print="printReport" :display-fullscreen="displayFullscreen" />
    </div>

    <div v-once ref="container" class="embed-container" />
  </div>
</template>

<script>
import axios from 'axios'
import * as pbi from 'powerbi-client'

import {config} from 'src/js/infrastructure'

const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)

export default {
  props: {
    reportId: String,
    type: String,
    config: Object
  },
  data () {
    return {
      accessToken: null,
      embedUrl: null,
      bootstrappedReport: null,
      embeddedReport: null
    }
  },
  computed: {
    mergedConfig () {
      const {type, reportId, config, accessToken, embedUrl} = this

      return {
        id: reportId,
        type,
        accessToken,
        tokenType: pbi.models.TokenType.Embed,
        embedUrl,
        // other options
        pageView: 'actualSize',
        permissions: pbi.models.Permissions.All,
        ...config
      }
    }
  },
  methods: {
    bootstrap () {
      this.bootstrappedReport = powerbi.bootstrap(this.$refs.container, {
        type: this.type,
        hostname: config.powerBiEmbedHost
      })
    },
    tryToEmbed () {
      if (!this.accessToken || !this.bootstrappedReport || this.embeddedReport) return

      this.embed()
    },
    embed () {
      this.embeddedReport = powerbi.embed(this.$refs.container, this.mergedConfig)

      if (this.type === 'dashboard') {
        this.embeddedReport.on('tileClicked', event => this.$emit('tile-clicked', event))
      }
    },
    async printReport () {
      if (!this.embeddedReport) return

      try {
        await this.embeddedReport.print()
      } catch (error) {
        console.error(error)
      }
    },
    displayFullscreen () {
      this.embeddedReport.fullscreen()
    }
  },
  watch: {
    accessToken: 'tryToEmbed',
    bootstrappedReport: 'tryToEmbed'
  },
  async created () {
    const response = await axios.get(`/api/v2/power-bi/workspaces/${this.$route.params.workspaceId}/${this.type}s/${this.reportId}`)

    const {accessToken, embedUrl} = response.data

    Object.assign(this, {accessToken, embedUrl})
  },
  mounted () {
    this.bootstrap()
  },
  beforeDestroy () {
    powerbi.reset(this.$refs.container)
  }
}
</script>

<style lang="scss" scoped>
.embed-wrapper {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template: auto 1fr / 1fr;
}

.controls {
  grid-area: 1 / 1 / 2 / 2;
}

.embed-container {
  grid-area: 2 / 1 / 3 / 2;

  ::v-deep > iframe {
    border: none;
  }
}
</style>
